import { PatronReservation, RediaPlatform, Schemas, RediaPlatformProps } from "../client/interfaces";
import { mockLoans } from "./mockLoans";
import { mockPatron } from "./mockPatron";
import { mockPublications } from "./mockPublications";
import { mockReservation, mockReservations } from "./mockReservations";
import { SessionStore } from "../client/sessionStore";
import { mockBranches } from "./mockBranches";
import { mockHoldings } from "./mockHoldings";

const mockDataLocalStorageKey = "mock-data";
export const mockLoansLocalStorageKey = mockDataLocalStorageKey + "-loans";
export const mockReservationsLocalStorageKey = mockDataLocalStorageKey + "-reservations";

export const createRediaPlatformMockClient = (props: RediaPlatformProps): RediaPlatform =>
  new RediaPlatformMockClient(props);

/* eslint-disable @typescript-eslint/no-unused-vars */
class RediaPlatformMockClient implements RediaPlatform {
  public environment = "test";
  public customerId = "bstestcmsbib";
  private sessionStore: SessionStore;
  public readonly isMock = true;

  public constructor({ sessionStore }: Pick<RediaPlatformProps, "sessionStore">) {
    this.sessionStore = sessionStore;
  }

  public async login(username: string, password: string) {
    this.sessionStore.set({
      token: { type: "bearer", token: "21", expiresTime: "2021-01-01T00:00:00.000Z" },
      user: mockPatron,
      customerId: this.customerId,
    });
  }

  public async logout() {
    this.sessionStore.clear();
  }

  public async getConfiguration() {
    const config: Schemas["configuration"]["ConfigurationContent"] = {
      productCode: "content",
      name: "Mockoteket",
    };
    return config;
  }

  public async getCustomerGroups() {
    return [];
  }

  public getUser() {
    return mockPatron;
  }

  public async refreshUserProfile() {
    // Do nothing
  }

  public async getReservations(): Promise<PatronReservation[]> {
    return getFromLocalStorage(mockReservationsLocalStorageKey) ?? mockReservations;
  }

  public async deleteReservation(reservationId: string) {
    // Do nothing
  }

  public async createReservation(reservationId: string, pickupBranchCode: string): Promise<PatronReservation> {
    return mockReservation;
  }

  public async getLoans() {
    return getFromLocalStorage(mockLoansLocalStorageKey) ?? mockLoans;
  }

  public async renewLoan(loanId: string) {
    const loan = mockLoans[0];
    if (!loan) {
      // Make TS happy
      throw new Error("Loan not found");
    }
    return loan;
  }

  public async getPublications(publicationIds: string[]) {
    return mockPublications;
  }

  public async getHoldings(publicationIds: string[]) {
    return mockHoldings;
  }

  public async getBranches() {
    return mockBranches;
  }

  public async getBranch(branchCode: string) {
    return mockBranches[branchCode];
  }
}

const getFromLocalStorage = (key: string) => {
  const localStorageValue = localStorage.getItem(key);
  if (!localStorageValue) return undefined;
  try {
    return JSON.parse(localStorageValue);
  } catch (e) {
    console.log("Ugyldig data i local storage");
    return undefined;
  }
};
