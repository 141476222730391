import { RediaPlatformSession } from "./interfaces";

export interface SessionStore {
  get: () => RediaPlatformSession | undefined;
  set: (data: RediaPlatformSession) => void;
  clear: () => void;
}

/**
 * Standard session store that can be used with SessionStorage or LocalStorage.
 */
export const createSessionStore = ({
  storage,
  key,
  onChange,
}: {
  storage: Storage;
  key: string;
  onChange?: (data: RediaPlatformSession | undefined) => any;
}): SessionStore => {
  const store = {
    get: () => {
      const data = storage.getItem(key);
      return data ? (JSON.parse(data) as RediaPlatformSession) : undefined;
    },
    set: (data: RediaPlatformSession) => {
      storage.setItem(key, JSON.stringify(data));
      if (onChange) onChange(data);
    },
    clear: () => {
      storage.removeItem(key);
      if (onChange) onChange(undefined);
    },
  };
  // Set initial state upon loading
  if (onChange) onChange(store.get());
  return store;
};

/**
 * Simple in-memory store that can be used in API routes (backend) and tests.
 */
export const createInMemorySessionStore = (): SessionStore => {
  let session: RediaPlatformSession | undefined;
  return {
    set: (newSession: RediaPlatformSession) => {
      session = newSession;
    },
    get: () => session,
    clear: () => {
      session = undefined;
    },
  };
};
