import { useCommonData } from "../../components/layout/CommonDataProvider";
import { useFeatureToggle } from "../../utils/hooks/featureToggles";
import { useIsOnCollectionPage } from "./useIsOnCollectionPage";
import { useIsOnSearchPage } from "./useIsOnSearchPage";

export const useNewSearch = () => {
  const isOnSearchPage = useIsOnSearchPage();
  const isOnCollectionPage = useIsOnCollectionPage();
  const { site } = useCommonData();

  return (
    (useFeatureToggle("search") || isOnSearchPage || isOnCollectionPage) && site?.librarySystem?.type === "bibliofil"
  );
};
