import createClient from "openapi-fetch";
import { RediaPlatformEnvironment, RediaPlatformSession } from "./interfaces";
import { SessionStore } from "./sessionStore";
import { configurationPaths } from "../types";
import { RediaPlatformConfigurationApiError } from "./errors";
import { getRediaPlatformBaseUrl } from "./getRediaPlatformBaseUrl";

type NewSessionResult =
  | { session: RediaPlatformSession; error?: never }
  | { session?: never; error: RediaPlatformConfigurationApiError };

// The three types below should really be available globally, but are not for some reason. Tsconfig issue?
type RequestInfo = Parameters<typeof fetch>[0];
type RequestInit = NonNullable<Parameters<typeof fetch>[1]>;
type FetchAPI = (input: RequestInfo | URL, init?: RequestInit) => Promise<Response>;

interface RediaPlatformSessionMiddlewareProps {
  environment: RediaPlatformEnvironment;
  clientId: string;
  customerId: string;
  sessionStore: SessionStore;
}

/**
 * Fetch middleware that is responsible for managing the Redia Platform session.
 * It creates the session transparently when needed, and could be extended to
 * refresh the session automatically in the future.
 */
export class RediaPlatformSessionMiddleware {
  /**
   * Redia Platform client identifier.
   */
  private readonly clientId: string;

  /**
   * Redia Platform customer identifier.
   */
  private readonly customerId: string;

  /**
   * API client for the Redia Platform Configuration API, used to fetch session tokens.
   */
  private readonly configurationApi: ReturnType<typeof createClient<configurationPaths>>;

  /**
   * Key-value store where we store the current session. The implementation will
   * normally be based on LocalStorage or SessionStorage, but can also be an
   * in-memory store for testing.
   */
  private readonly sessionStore: SessionStore;

  /**
   * Pending session creation request.
   */
  private pendingSession: Promise<NewSessionResult> | undefined;

  constructor({ environment, clientId, customerId, sessionStore }: RediaPlatformSessionMiddlewareProps) {
    this.clientId = clientId;
    this.customerId = customerId;
    this.sessionStore = sessionStore;
    this.configurationApi = createClient<configurationPaths>({
      baseUrl: getRediaPlatformBaseUrl({ api: "configuration", environment }),
    });

    const session = this.sessionStore.get();
    if (session && session?.customerId !== customerId) {
      // Tøm sesjonen hvis `customerId` har blitt endret i Nettstedinnstillinger i Sanity.
      console.log("Clearing Redia Platform session because customerId has changed");
      this.sessionStore.clear();
    }
  }

  public getSession() {
    return this.sessionStore.get();
  }

  /**
   * Clear the current session. Call this after logout.
   */
  public clearSession() {
    return this.sessionStore.clear();
  }

  private async requestNewSession(): Promise<NewSessionResult> {
    const response = await this.configurationApi.POST("/api/session/token", {
      body: {
        customerCode: this.customerId,
        secret: this.clientId, // "Ikke egentlig en secret" iflg. Redia. Vi kaller det clientId.
        productCode: "content",
        version: "v1.99.99",
        platform: "web",
      },
    });
    return response.error
      ? { error: new RediaPlatformConfigurationApiError(response.error) }
      : {
          session: {
            token: response.data?.token,
            customerId: this.customerId,
          },
        };
  }

  /**
   * Create a new session. If a session is already pending creation, wait for
   * that one to complete instead of creating a new one.
   */
  public async newSession(): Promise<NewSessionResult> {
    if (this.pendingSession) {
      return this.pendingSession;
    }
    this.pendingSession = this.requestNewSession();
    try {
      const { session, error } = await this.pendingSession;
      if (session) {
        this.sessionStore.set(session);
        return { session };
      }
      return { error };
    } finally {
      this.pendingSession = undefined;
    }
  }

  public hasSessionToken() {
    return !!this.sessionStore.get();
  }

  public getFetch(next: FetchAPI = fetch) {
    return async (input: URL | RequestInfo, init: RequestInit = {}) => {
      if (!this.hasSessionToken()) {
        const { error } = await this.newSession();
        if (error) throw error;
      }
      const response = await next(input, this.addAuthorizationHeader(init));
      if (this.hasSessionToken() && response.status === 401) {
        console.log(`⏰ User session expired, clearing session`);
        this.clearSession();
      }
      return response;
    };
  }

  private addAuthorizationHeader(init: RequestInit): RequestInit {
    const token = this.sessionStore.get()?.token?.token;
    const headers = new Headers(init.headers ?? {});
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return {
      ...init,
      headers,
    };
  }
}
